import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './ItemDetails.css';
import {Helmet} from "react-helmet";


function ItemDetails() {

    const [qrMapping, setQrMapping] = useState({});
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const qrMappingUrl = 'https://hlpzh4g3w6.execute-api.eu-central-1.amazonaws.com/dev/qrurlmapping/'
    const activateCardUrl = 'https://socialbee.store/products/activate-google-rating-nfc-card/';

    useEffect(() => {
        fetch(qrMappingUrl + id)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setQrMapping(data);
                setLoading(false);

                //post to increase the count by one every time it's navigated to google review url
                fetch(qrMappingUrl + id, {
                    method: 'POST',
                })
                    .then((data) => console.log(data))
                    .catch((err) => {
                        console.log(err);
                    });

                if(data.status==="PURCHASED"){
                    console.log("navigate to google review url")
                    window.location.replace(data.googleRatingUrl);
                }
            })
            .catch((err) => {
                window.location.replace(activateCardUrl);
                console.log(err);
            });
    }, []);




    const activateNow = async () => {


        //TODO define qr types for each product type
        if(qrMapping.qrType === "sticker"){
            window.location.replace("https://socialbee.store/product/activate-your-google-review-card/");

        }else if(qrMapping.qrType === "nfccard"){
            window.location.replace("https://socialbee.store/product/activate-google-rating-nfc-card/");
        }

        window.location.replace("https://socialbee.store/product/activate-google-rating-nfc-card/");
    }

    const tryDemo = async () => {
        console.log(qrMapping);
        window.location.replace(qrMapping.googleRatingUrl);
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (qrMapping && qrMapping.status !== "PURCHASED") {
        return (
            <div className="container">

                <Helmet>
                    <script async src="https://js.stripe.com/v3/buy-button.js" />
                </Helmet>

                <div className="row">
                    <div className="col-md-6">
                        <video width="640" height="640" autoPlay muted loop>
                            <source
                                src="https://socialbeestatic.s3.eu-central-1.amazonaws.com/socialbee-ad-video-1.mp4"
                                type="video/mp4"
                            />
                        </video>

                    </div>

                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-center">

                        <stripe-buy-button
                            buy-button-id="buy_btn_1OLSoHGX78fX6xQidaDXD5a4"
                            publishable-key="pk_live_S5STW30pTessWe60Q87gJQl1"
                        />

                        <button onClick={tryDemo} className="btn btn-warning btn-lg btn-block mt-3">
                            Try demo
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemDetails;