import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import JSZip from 'jszip';
import './../styles.css';
import { generateRandomStrings } from './Utils';

function QrGenerator() {
    const randomStrings = generateRandomStrings(200);
    const baseUrl = 'https://qr.socialbee.store';
    const downloadContainerRef = useRef(null);

    const downloadAllCodes = async () => {


        const zip = new JSZip();
        const downloadContainer = downloadContainerRef.current;
        var links = [];
        if (downloadContainer) {
            for (let index = 0; index < randomStrings.length; index++) {
                const str = randomStrings[index];
                const qrCanvas = downloadContainer.querySelector(`#QR-${index}`);
                if (qrCanvas) {
                    const pngUrl = qrCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                    zip.file(`${str}.png`, pngUrl.split(';base64,')[1], { base64: true });
                }
                const link = baseUrl + "/" + str;
                links.push(link);
            }
            downloadCSV(links, "test.csv")
        }



        zip.generateAsync({ type: 'blob' }).then((content) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = 'qr_codes.zip';
            downloadLink.click();
        });
    };


    function downloadCSV(arrayOfStrings, fileName)  {

        // Convert the array of strings to a CSV-formatted string
        const csvContent = arrayOfStrings.map(str => `"${str}"`).join("\n");

        // Create a Blob object
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', fileName + '.csv');
        link.style.visibility = 'hidden';

        // Trigger a click event to initiate the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up by revoking the URL
        URL.revokeObjectURL(url);
    };


    return (
        <div className="App">
            <h1>Logo QR code download</h1>
            <button type="button" onClick={downloadAllCodes}>
                Download all QR Codes as ZIP
            </button>

            <div ref={downloadContainerRef} style={{ display: 'none' }}>
                {randomStrings.map((str, index) => (
                    <QRCode
                        key={index}
                        value={`${baseUrl}/${str}`}
                        ecLevel="H"
                        size={350}
                        logoHeight={40}
                        logoWidth={40}
                        logoOpacity={1}
                        enableCORS={true}
                        qrStyle="dots"
                        eyeRadius={90}
                        id={`QR-${index}`}
                    />
                ))}
            </div>
        </div>
    );
}

export default QrGenerator;
