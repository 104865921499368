const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
};

const generateRandomStrings = (count) => {
    const randomStrings = [];

    for (let i = 0; i < count; i++) {
        const randomString = generateRandomString(6);
        randomStrings.push(randomString);
    }

    return randomStrings;
};

export { generateRandomStrings };
