import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/Home';
import ItemDetails from './components/ItemDetails';
import NotFound from './components/NotFound';
import QrGenerator from './components/QrGenerator';
import SingleQrGenerator from './components/SingleQrGenerator';

import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:id" element={<ItemDetails />} />
                    <Route path="/qrgenerate" element={<QrGenerator />} />
                    <Route path="/singleqrgenerate" element={<SingleQrGenerator />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
}



export default App;
