import { QRCode } from "react-qrcode-logo";
import React, { useRef } from 'react'; // Import useRef
import "./../styles.css";
import { generateRandomStrings } from "./Utils";

function SingleQrGenerator() {
    const randomStrings = generateRandomStrings(1);
    const baseUrl = "https://qr.socialbee.store";

    const downloadCode = (value) => {
        const canvas = document.getElementById(`QR-${value}`);
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${value}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const downloadAllCodes = () => {
        randomStrings.forEach(str => downloadCode(str));
    };

    return (
        <div className="App">
            <h1>Logo QR code download</h1>
            <br />
            {randomStrings.map((str, index) => (
                <div key={index}>
                    <QRCode
                        value={`${baseUrl}/${str}`}
                        ecLevel={"H"}
                        size={350}
                        logoHeight={40}
                        logoWidth={40}
                        logoOpacity={1}
                        enableCORS={true}
                        qrStyle="dots"
                        eyeRadius={100}
                        id={`QR-${str}`} // Use a unique ID for each QR code
                    />
                    <p>
                        Click for{" "}
                        <button type="button" onClick={() => downloadCode(str)}>
                            Download QR Code
                        </button>
                    </p>
                </div>
            ))}

            <h2>Random Alphanumeric Strings</h2>
            <div>
                {randomStrings.map((str, index) => (
                    <p key={index}>{baseUrl}/{str}</p>
                ))}
            </div>
            <button type="button" onClick={() => downloadAllCodes()}>
                Download all QR Codes
            </button>
        </div>
    );
}

export default SingleQrGenerator;
